const heroFormHighlighter = document.getElementById('hero-form-highlighter');
const heroFormOpcaoEmpresa = document.getElementById('hero-form-label-opcao-empresa');
const heroFormOpcaoIndependente = document.getElementById('hero-form-label-opcao-independente');
const heroFormCampoEmpresa = document.getElementById('hero-form-campo-empresa');
const heroFormCampoTipoETamanho = document.getElementById('hero-form-campo-tipo-e-tamanho');
const heroFormCampoSegmento = document.getElementById('hero-form-campo-segmento');

// MOVER O HIGHLIGHTER DE ACORDO COM A OPÇÃO SELECIONADA
heroFormOpcaoEmpresa.addEventListener('click', () => {
    heroFormHighlighter.className = 'segunda-opcao';
});

heroFormOpcaoIndependente.addEventListener('click', () => {
    heroFormHighlighter.className = 'primeira-opcao';
});


// OMITIR CAMPOS DE ACORDO COM A OPÇÃO SELECIONADA
const heroFormRadioIndependente = document.getElementById('hero-form-opcao-independente');
const heroFormRadioEmpresa = document.getElementById('hero-form-opcao-empresa');
heroFormRadioIndependente.addEventListener('change', () => {
    if (heroFormRadioIndependente.checked) {
        heroFormCampoEmpresa.style.display = 'none';
        heroFormCampoTipoETamanho.style.display = 'none';
        heroFormCampoSegmento.style.display = 'none';
    } else {
        heroFormCampoEmpresa.style.display = 'block';
        heroFormCampoTipoETamanho.style.display = 'block';
        heroFormCampoSegmento.style.display = 'block';
    }
});

heroFormRadioEmpresa.addEventListener('change', () => {
    if (heroFormRadioEmpresa.checked) {
        heroFormCampoEmpresa.style.display = 'block';
        heroFormCampoTipoETamanho.style.display = 'block';
        heroFormCampoSegmento.style.display = 'block';
    } else {
        heroFormCampoEmpresa.style.display = 'none';
        heroFormCampoTipoETamanho.style.display = 'none';
        heroFormCampoSegmento.style.display = 'none';
    }
});

// ------------------------------ SEGUNDO FORMULÁRIO ------------------------------ //

const highlighter = document.getElementById('highlighter');
const opcaoEmpresa = document.getElementById('label-opcao-empresa');
const opcaoIndependente = document.getElementById('label-opcao-independente');
const campoEmpresa = document.getElementById('campo-empresa');
const campoTipoETamanho = document.getElementById('campo-tipo-e-tamanho');
const campoSegmento = document.getElementById('campo-segmento');

// MOVER O HIGHLIGHTER DE ACORDO COM A OPÇÃO SELECIONADA
opcaoEmpresa.addEventListener('click', () => {
    highlighter.className = 'primeira-opcao';
});

opcaoIndependente.addEventListener('click', () => {
    highlighter.className = 'segunda-opcao';
});


// OMITIR CAMPOS DE ACORDO COM A OPÇÃO SELECIONADA
const radioIndependente = document.getElementById('opcao-independente');
const radioEmpresa = document.getElementById('opcao-empresa');
radioIndependente.addEventListener('change', () => {
    if (radioIndependente.checked) {
        campoEmpresa.style.display = 'none';
        campoTipoETamanho.style.display = 'none';
        campoSegmento.style.display = 'none';
    } else {
        campoEmpresa.style.display = 'block';
        campoTipoETamanho.style.display = 'block';
        campoSegmento.style.display = 'block';
    }
});

radioEmpresa.addEventListener('change', () => {
    if (radioEmpresa.checked) {
        campoEmpresa.style.display = 'block';
        campoTipoETamanho.style.display = 'block';
        campoSegmento.style.display = 'block';
    } else {
        campoEmpresa.style.display = 'none';
        campoTipoETamanho.style.display = 'none';
        campoSegmento.style.display = 'none';
    }
});